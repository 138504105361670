var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Employee Name")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Employee Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"fullname","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"None","options":_vm.employeenameoption},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var fullname = ref.fullname;
var profile_image = ref.profile_image;
var username = ref.username;
var color = ref.color;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{class:'font-weight-bolder text-' + color},[_vm._v(" "+_vm._s(fullname)+" ("+_vm._s(username)+")")])]}}],null,true),model:{value:(_vm.employee_id),callback:function ($$v) {_vm.employee_id=$$v},expression:"employee_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Leave Type")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Leave Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","placeholder":"None","options":['Full Day', 'First Half', 'Second Half', 'LWP']},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Start Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Start Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background":"transparent"},attrs:{"config":{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                }},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("End Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"End Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background":"transparent"},attrs:{"config":{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                  minDate:_vm.startdate
                }},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Reason")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Reason"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Enter Reason"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"primary","type":"submit","disabled":_vm.flag},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }